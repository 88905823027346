<template>
    <picture>
        <RespImgSrc
            tag-type="source"
            image-src="/missing-children/bg-gradient.png"
            :srcset="[1920, 1440, 1024, 768, 580, 320]"
            :dpr="[1]"
            :format="['webp', 'avif', 'jpg']"
            :width="1920"
            :height="900"
            sizes="(min-width: 1920x) 1920px,  100vw"
        />
        <RespImgSrc
            tag-type="img"
            image-src="/missing-children/bg-gradient.png"
            :srcset="[320]"
            :dpr="[1]"
            sizes="100vw"
            :format="['png']"
            :width="1920"
            :height="900"
            :additional-attrs="{ loading: 'lazy', alt: 'Background Pattern', class: 'gradient-bg' }"
        />
    </picture>
</template>

<style lang="scss">
.gradient-bg {
    position: absolute;
    top: 0;
    right: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    animation: fade-in-out-1 6s 2s ease-in-out infinite;
}

@keyframes fade-in-out-1 {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 0.5;
    }
}
</style>
