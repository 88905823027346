<script setup lang="ts">
const header = ref<HTMLElement | null>(null)
const headerClass = ref('hidden')
let lastScrollPosition = 0

// Using useScroll to get the current scroll position and direction
const { y } = useScroll(window, {
    behavior: 'smooth'
})

// Get the height of the header and add some extra space
const headerHeight = computed(() => {
    if (header.value) {
        return header.value.clientHeight / 5
    }
    return 0
})

watch(y, (currentY) => {
    if (currentY > headerHeight.value) {
        if (currentY < lastScrollPosition) {
            headerClass.value = 'page-header-visible'
        } else {
            headerClass.value = 'page-header-hidden'
        }
    } else {
        headerClass.value = 'page-header-visible-top'
    }
    lastScrollPosition = currentY
}, { immediate: true })
</script>

<template>
    <header ref="header" class="page-header" :class="headerClass">
        <div class="page-header-inner">
            <PageLogo />
        </div>
    </header>
</template>

<style lang="scss">
.page-header {
    position: fixed;
    top: 0;
    align-items: center;
    z-index: z('page-header');
    transition: transform 0.5s ease-in-out;

    @include fullbleed;

    &::before {
        content: '';
        position: fixed;
        top: -1px;
        left: 0;
        right: 0;
        z-index: z('page-header-gradient');
        transition: opacity 0.5s ease-in-out;
        pointer-events: none;
        border-top: $secondary 30px solid;
        background: $body-bg-gradient-top;
        min-height: $header-shadow-height-mobile;

        @include bp(medium1) {
            min-height: $header-shadow-height-tablet;
            border-top: $secondary 60px solid;
        }

        @include bp(large2) {
            min-height: $header-shadow-height-desktop;
        }
    }

    &.page-header-hidden {
        transform: translateY(-100%);

        &::before {
           opacity: 0;
        }
    }

    &.page-header-visible {
        transform: translateY(0);
    }

    &.page-header-visible-top {
        transform: translateY(0);

        &::before {
            border-top: 0 none;
            min-height: $header-height-mobile;
        }

        @include bp(medium1) {
            min-height: $header-height-tablet;
            border-top: 0 none;
        }

        @include bp(large2) {
            min-height: $header-height-desktop;
        }
    }
}

.page-header-inner {
    grid-column: 2 / -2;
    position: relative;
    z-index: z('page-header-inner');
    padding-block: $page-grid-gap-mobile;
    transition: border-color 0.5s ease-in-out;

    @include bp(large1) {
        border-bottom: $page-header-border 2px solid;
    }

    .page-header-hidden & {
        @include bp(large1) {
            border-color: transparent;
        }
    }

    .page-header-visible & {
        @include bp(large1) {
            border-color: transparent;
        }
    }
}
</style>
