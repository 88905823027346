<template>
    <div class="page-main-wrapper">
        <slot />
    </div>
</template>

<style lang="scss">
.page-main-wrapper {
    min-height: 100dvh;
    grid-template-rows: auto 1fr auto;
    @include page-grid;
}

.page-pattern {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: z('page-pattern');
}

.gradient-bg {
    position: absolute;
    top: 0;
    right: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    animation: fade-in-out-1 6s 2s ease-in-out infinite;
}

.pattern-bg {
    position: absolute;
    top: 0;
    right: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    animation: fade-in-out-2 5s ease-in-out infinite;
}

@keyframes fade-in-out-1 {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 0.5;
    }
}

@keyframes fade-in-out-2 {
    0% {
        opacity: 0.2;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 0.2;
    }
}
</style>
