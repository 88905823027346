<script setup>
const props = defineProps({
    tagType: {
        type: String,
        default: 'source',
        required: false
    },
    imageSrc: {
        type: String,
        required: true
    },
    dpr: {
        type: Array,
        required: false,
        default: () => []
    },
    srcset: {
        type: Array,
        required: true
    },
    width: {
        type: Number,
        required: true
    },
    height: {
        type: Number,
        required: true
    },
    format: {
        type: Array,
        required: true
    },
    media: {
        type: String,
        default: '',
        required: false
    },
    sizes: {
        type: String,
        default: '100vw',
        required: false
    },
    quality: {
        type: Number,
        default: 45,
        required: false
    },
    additionalAttrs: {
        type: Object,
        default: () => ({})
    }
})

/**
 * Generates the srcset for an image based on the provided parameters.
 *
 * @param {string} imagePath - The path of the image.
 * @param {Array<number>} widths - The array of widths for the image.
 * @param {string} format - The desired format of the image.
 * @param {number} quality - The desired quality of the image.
 * @param {Array<number>} dpr - The array of device pixel ratios.
 * @return {string} - The generated srcset string.
 */
function generateSrcset(imagePath, widths, format, quality, dpr) {
    const dprTrue = dpr?.length > 0
    let srcset

    if (dprTrue) {
        srcset = dpr.map((dprValues) => {
            return widths.map((width) => {
                const url = `/_ipx/w_${width * dprValues}&f_${format}&q_${quality}/${imagePath}`
                return `${url} ${dprValues}x`
            })
        })
    } else {
        srcset = widths.map((width) => {
            const url = `/_ipx/w_${width}&f_${format}&q_${quality}/${imagePath}`
            return `${url} ${width}w`
        })
    }

    srcset = srcset.join(', ')

    return srcset.replace(/&amp;/g, '&')
}

/**
 * Generates an image with the specified imagePath, width, format, and quality.
 *
 * @param {string} imagePath - The path of the image to generate.
 * @param {number} width - The desired width of the generated image.
 * @param {string} format - The desired format of the generated image.
 * @param {number} quality - The desired quality of the generated image.
 * @return {type} The generated image with the specified parameters.
 */
function generateImage(imagePath, width, format, quality) {
    const img = useImage()
    const modifiers = { width, format, quality }
    return img.getSizes(imagePath, { modifiers })
}

const imageSources = computed(() => {
    return props.format.map((imgformat) => {
        const srcset = generateSrcset(props.imageSrc, props.srcset, imgformat, props.quality, props.dpr)
        const media = props.media ? `${props.media}` : ''
        const format = `image/${imgformat}`
        const sizes = props.sizes
        const width = props.width
        const height = props.height
        return { srcset, media, format, sizes, width, height }
    })
})

const standaloneImageSrc = computed(() => {
    const { src } = generateImage(props.imageSrc, props.width, props.format[0], props.quality)
    const srcset = generateSrcset(props.imageSrc, props.srcset, props.format[0], props.quality, props.dpr)
    const media = props.media ? `${props.media}` : ''
    const format = `image/${props.format[0]}`
    const sizes = props.sizes
    const width = props.width
    const height = props.height
    return { src, srcset, media, format, sizes, width, height }
})
</script>

<template>
    <template v-if="props.tagType === 'source'">
        <source
            v-for="(imageSource, index) in imageSources"
            :key="`responsive-image-source-${index}`"
            :width="imageSource.width"
            :height="imageSource.height"
            :media="imageSource.media"
            :srcset="imageSource.srcset"
            :sizes="imageSource.sizes"
            :type="imageSource.format"
            v-bind="additionalAttrs"
        >
    </template>
    <template v-else-if="tagType === 'img'">
        <img
            :src="standaloneImageSrc.src"
            :width="standaloneImageSrc.width"
            :height="standaloneImageSrc.height"
            :srcset="standaloneImageSrc.srcset"
            :sizes="standaloneImageSrc.sizes || null"
            v-bind="additionalAttrs"
        >
    </template>
</template>
