<script setup>
defineProps({
    error: {
        type: Object,
        default: () => {}, // Initialize as an empty object by default
        validator: (rawValue) => {
            // Check if message is type of string if exists
            if (rawValue.message) {
                return typeof rawValue.message === 'string'
            }

            // Check if statusCode is type of number if exists
            if (rawValue.statusCode) {
                return typeof rawValue.statusCode === 'number'
            }
        }
    }
})

const { data: staticKeys } = await useFetchMessages()

// Clears the error and redirects to the homepage
const handleClearError = () => {
    clearError({ redirect: '/' })
}

const errorButtonConfig = computed(() => {
    return {
        buttonType: 'primary',
        type: 'div',
        active: true,
    }
})

// Seo
useSeoMeta({
    titleTemplate: `%s - ${staticKeys.value?.title}`,
    title: staticKeys.value?.error.title
})
</script>

<template>
    <PageMainWrapper>
        <PageHeader />
        <PageMainContainer>
            <div class="error-page">
                <div class="error-page-content">
                    <div class="error-status-code">
                        {{ error?.statusCode }}
                    </div>
                    <p>
                        {{ staticKeys?.error?.general_error_headline }}
                    </p>

                    <BaseButton :base-button-config="errorButtonConfig" @click="handleClearError">
                        {{ staticKeys?.error?.general_error_button }}
                    </BaseButton>
                </div>
            </div>
        </PageMainContainer>
        <PageFooter />
        <PagePattern />
    </PageMainWrapper>
</template>

<style lang="scss">
.error-page {
    padding-block: px2rem(60);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100dvh - 110px);
    grid-column: 2/-2;
    position: relative;

    @include bp(medium1) {
        min-height: calc(100dvh - 148px);
    }
}

.error-status-code {
    font-size: clamp(100px, 40vw, 400px);
    line-height: 1;
    opacity: 0.5;
    animation: fade-in-out-3 5s ease-in-out infinite;
    font-family: $font-headline-special;
}

.error-page-content {
    text-align: center;
    margin-block-start: px2rem(-120);

    p {
        margin: 0 0 px2rem(40);
    }
}

@keyframes fade-in-out-3 {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.9;
    }

    100% {
        opacity: 0.6;
    }
}
</style>
