<script setup>
const { data: staticKeys } = await useFetchMessages()

const currentYear = computed(() => {
    return new Date().getFullYear()
})

/**
 *  Show Cookiebot
 */
const showCB = () => {
    Cookiebot.show()
}
</script>

<template>
    <footer class="page-footer">
        <div class="page-footer-inner">
            <nav class="page-footer-legal">
                <address class="page-footer-address" v-html="staticKeys?.footer?.address" />

                <p v-html="staticKeys?.footer?.contact" />

                <p v-html="staticKeys?.footer?.hotline" />

                <ul class="page-footer-legal-menu">
                    <li v-for="(link, index) in staticKeys?.footer?.menu" :key="`footer-link-${index}`">
                        <NuxtLink v-if="link.url" :to="link.url" :target="link.external? '_blank' : ''" :rel="link.external? 'noopener noreferrer' : ''">
                            {{ link.label }}
                        </NuxtLink>
                        <div v-else class="cookiebot-cta" @click="showCB">
                            {{ link.label }}
                        </div>
                    </li>
                </ul>

                <p class="page-footer-copyright" v-html="staticKeys.footer.copyRight.replace('%s', currentYear)" />
            </nav>
        </div>
    </footer>
</template>

<style lang="scss">
.page-footer {
    border-top: $page-footer-border 2px solid;
    background: $page-footer-bg;
    position: relative;
    z-index: z('page-footer');

    @include fullbleed;

    .cookiebot-cta,
    a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
    }
}

.page-footer-inner {
    grid-column: 2 / -2;
    padding-block: $page-grid-gap-mobile * 2;

    @include section-padding;
}

.page-footer-legal {
    grid-column: 1 / -1;
}

.page-footer-address {
    font-weight: 800;
    font-family: $font-headline;
    font-style: normal;
}

.page-footer-legal-menu {
    display: flex;
    gap: px2rem(10);
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: px2rem(60) 0 0;

    li {
        color: $page-footer-legal;
        font-size: px2rem(14);

        &:last-child {
            &::after {
                display: none;
            }
        }

        &::after {
            display: inline-block;
            content: '/';
            padding-inline-start: px2rem(10);
        }
    }
}

.page-footer-copyright {
    color: $page-footer-legal;
    font-size: px2rem(14);
    margin: px2rem(10) 0 0;
}
</style>
