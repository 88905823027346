<script setup>
const props = defineProps({
    baseButtonConfig: {
        type: Object,
        required: true,
        default: () => {},
        validator: (rawValue) => {
            // Check if type is string and contains set values (required)
            const isValidType = typeof rawValue.type === 'string' && ['submit', 'external', 'file', 'div', 'internal'].includes(rawValue.type)

            // Check if type is string and contains set values (required)
            const isValidButtonType = typeof rawValue.buttonType === 'string' && ['primary', 'secondary'].includes(rawValue.buttonType)

            // Check if active is boolean if set (optional)
            const isActive = rawValue.active === undefined || typeof rawValue.active === 'boolean'

            // Check if large is boolean if set (optional)
            const isLarge = rawValue.large === undefined || typeof rawValue.large === 'boolean'

            // Check if isPending is boolean if set (optional)
            const isPending = rawValue.isPending === undefined || typeof rawValue.isPending === 'boolean'

            // Check if disabled is boolean if set (optional)
            const isDisabled = rawValue.disable === undefined || typeof rawValue.disable === 'boolean'

            // Check if link is string if set (optional)
            const hasLink = rawValue.link === undefined || typeof rawValue.link === 'string'

            // Check if center is boolean if set (optional)
            const hasValidCenter = rawValue.center === undefined || typeof rawValue.center === 'boolean'

            return isValidType && isValidButtonType && isActive && isLarge && isPending && isDisabled && hasLink && hasValidCenter
        }
    }
})

const isSubmitBtn = computed(() => props.baseButtonConfig?.type === 'submit')
const isExternalLink = computed(() => props.baseButtonConfig?.type === 'external')
const isDiv = computed(() => props.baseButtonConfig?.type === 'div')
const target = computed(() => isExternalLink.value ? '_blank' : null)
const rel = computed(() => isExternalLink.value ? 'noopener noreferrer' : null)
const type = computed(() => isSubmitBtn.value ? 'submit' : null)

const buttonClass = computed(() => {
    return {
        'btn-primary': props.baseButtonConfig?.buttonType === 'primary',
        'btn-secondary': props.baseButtonConfig?.buttonType === 'secondary',
        'btn-center': !props.baseButtonConfig?.center,
        'btn-active': props.baseButtonConfig?.active,
        'btn-large': props.baseButtonConfig?.large,
        'btn-pending': props.baseButtonConfig?.isPending
    }
})

const component = computed(() => isSubmitBtn.value ? 'button' : isDiv.value ? 'div' : resolveComponent('NuxtLink'))
</script>

<template>
    <component
        :is="component"
        :to="baseButtonConfig?.link"
        :class="buttonClass"
        class="btn-primary"
        :target="target"
        :rel="rel"
        :type="type"
        :disabled="baseButtonConfig?.disabled"
    >
        <span><slot /></span>
    </component>
</template>

<style lang="scss">
.btn-primary {
    @include btn($type: 'primary');
}

.btn-secondary {
    @include btn($type: 'secondary');
}
</style>
