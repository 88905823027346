<script setup>
import Logo from 'assets/logos/logo.svg?url'

const { data: staticKeys } = await useFetchMessages()
</script>

<template>
    <NuxtLink :to="'/'" class="page-default-layout logo-link" :title="staticKeys.logo_title">
        <img :src="Logo" :alt="staticKeys?.logo_title" width="285" height="147">
    </NuxtLink>
</template>

<style lang="scss">
.logo-link {
    grid-column: 1 / span 2;
    width: px2rem(110);
    text-decoration: none;
    display: flex;
    align-items: center;

    @include bp(medium1) {
        width: px2rem(120);
    }

    @include bp(large2) {
        width: px2rem(165);
    }

    > img {
        width: 100%;
        height: auto;
    }
}
</style>
