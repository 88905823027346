<template>
    <aside class="page-pattern">
        <PagePatternGradientImage />
        <PagePatternImage />
    </aside>
</template>

<style lang="scss">
.page-pattern {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100dvh;
    z-index: z('page-pattern');
}
</style>
