<script lang="ts" setup>
const config = useRuntimeConfig()

useHead({
    meta: [
        {
            name: 'msapplication-TileColor',
            content: '#ffffff'
        },
        {
            name: 'theme-color',
            content: '#ffffff'
        },
        {
            name: 'charset',
            content: 'utf-8'
        },
        {
            property: 'og:title',
            content: 'Initiative Vermisste Kinder'
        },
        {
            property: 'og:description',
            content: 'Jedes Jahr werden Jungen und Mädchen Opfer von Verbrechen.Die Initiative Vermisste Kinder arbeitet für die betroffenen Eltern und Familien vermisster Kinder.'
        },
        {
            property: 'og:image',
            content: `${config?.public?.siteUrl}/missing-children.jpg`
        },
        {
            property: 'og:image:width',
            content: '1200'
        },
        {
            property: 'og:image:height',
            content: '630'
        },
        {
            property: 'og:url',
            content: config?.public?.siteUrl
        },
        {
            name: 'twitter:card',
            content: 'summary_large_image'
        },
        {
            name: 'twitter:title',
            content: 'Initiative Vermisste Kinder'
        },
        {
            name: 'twitter:description',
            content: 'Jedes Jahr werden Jungen und Mädchen Opfer von Verbrechen.Die Initiative Vermisste Kinder arbeitet für die betroffenen Eltern und Familien vermisster Kinder.'
        },
        {
            name: 'twitter:image',
            content: `${config?.public?.siteUrl}/missing-children.jpg`
        }
    ],
    script: [
        {
            defer: true,
            src: 'https://cdn.jsdelivr.net/gh/philfung/add-to-homescreen@1.9/dist/add-to-homescreen.min.js',
        },

        {
            type: 'text/javascript',
            innerHTML: `window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments)}gtag("consent", "default", {ad_personalization: "denied",ad_storage: "denied",ad_user_data: "denied",analytics_storage: "denied",functionality_storage: "denied",personalization_storage: "denied",security_storage: "granted",wait_for_update: 500});gtag("set", "ads_data_redaction", true);gtag("set", "url_passthrough", true);`,
            // 'data-cookieconsent': 'Marketing'
        },
        {
            type: 'text/javascript',
            innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WZB6767Q');`,
            // 'data-cookieconsent': 'Marketing'
        },
        {
            'src': 'https://consent.cookiebot.com/uc.js',
            'id': 'Cookiebot',
            'data-culture': 'de',
            'data-cbid': 'cf64affd-df9b-45da-be1d-481d6e2bbe9f',
            // 'data-blockingmode': 'auto'
        },

    ],
    link: [
        {
            rel: 'apple-touch-icon',
            href: '/apple-touch-icon.png'
        },
        {
            rel: 'icon',
            type: 'image/svg+xml',
            href: '/favicon.svg'
        },
        {
            rel: 'icon',
            type: 'image/png',
            href: '/favicon.png'
        },
        {
            rel: 'manifest',
            href: '/site.webmanifest'
        },
        {
            rel: 'mask-icon',
            href: '/safari-pinned-tab.svg',
            color: '#00b2fb'
        }
    ]
})

// Trigger reload on Cookiebot accept or decline
/*
onMounted(async () => {
    window?.addEventListener('CookiebotOnDecline', function (_) {
        // window.location.reload()
    }, false)
})
*/
</script>

<template>
    <div>
        <NuxtRouteAnnouncer />
        <NuxtLoadingIndicator
            :color="'#00b2fb'"
            :duration="2000"
            :height="3"
            :throttle="200"
        />
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>
