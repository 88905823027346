<template>
    <main class="page-main-container">
        <slot />
    </main>
</template>

<style lang="scss">
.page-main-container {
    grid-column: 1 / -1;
    position: relative;
    z-index: z('page-main-container');

    @include fullbleed;
}
</style>
