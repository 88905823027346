<template>
    <picture>
        <RespImgSrc
            tag-type="source"
            image-src="/missing-children/bg.png"
            :srcset="[1440, 1024, 768, 580, 320]"
            :dpr="[1]"
            :format="['webp', 'avif', 'jpg']"
            :width="1440"
            :height="900"
            sizes="(min-width: 1440px) 1440,  100vw"
        />
        <RespImgSrc
            tag-type="img"
            image-src="/missing-children/bg.png"
            :srcset="[320]"
            :dpr="[1]"
            sizes="100vw"
            :format="['png']"
            :width="1440"
            :height="900"
            :additional-attrs="{ loading: 'lazy', alt: 'Background Pattern', class: 'pattern-bg' }"
        />
    </picture>
</template>

<style lang="scss">
.pattern-bg {
    position: absolute;
    top: 0;
    right: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    opacity: 0.2;
    animation: fade-in-out-2 5s ease-in-out infinite;

    .home-instructions-pattern & {
        animation: initial;
        opacity: 1;
    }
}

@keyframes fade-in-out-2 {
    0% {
        opacity: 0.2;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 0.2;
    }
}
</style>
